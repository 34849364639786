import React, { useMemo } from 'react';
import { Checkbox } from '@kit/ui/Checkbox';
import { Select } from '@kit/ui/Select';
import { useSelectionWithGroups } from '@features/SystemPortfolio/List/Table/useSelection';
import { COLUMNS, PER_PAGE_OPTIONS } from '@features/SystemPortfolio/constants';
import { XIcon } from '@kit/ui/icons/X';
import { useMapWidth, useShowMap } from '@features/SystemPortfolio/List/Table/useShowMap';
import { Switcher } from '@common/ui';
import { columnStyleWithMap } from '@features/SystemPortfolio/List/Table/styled';
import { MultilineEllipsis } from '@kit/ui/MultilineEllipsis';
import { useClientFilterState } from '../../useClientFilterState';
import {
  MapSwitch,
  PerPage,
  RightSide,
  SelectedLabel,
  SelectionAndPerPage,
  SelectionControlPanel,
  Table,
  TitleCell
} from './styled';

export const TableHeader = () => {
  const { clientFilters, updateFilters } = useClientFilterState();

  const isGroupedData = !!clientFilters.groupBy;

  const { selectedIds, clearSelection, isAllSelected, toggleAllSelected } = useSelectionWithGroups();

  const [showMap, setShowMap] = useShowMap();
  const [mapWidth] = useMapWidth();
  const columnStyle = useMemo(() => (showMap ? columnStyleWithMap : {}), [showMap]);

  return (
    <>
      <SelectionAndPerPage>
        <div>
          {selectedIds.length > 0 && (
            <SelectionControlPanel>
              <XIcon onClick={clearSelection} size="24px" color="#9C9CAA" />
              <SelectedLabel>{selectedIds.length} selected:</SelectedLabel>
            </SelectionControlPanel>
          )}
        </div>
        <RightSide>
          <MapSwitch>
            <Switcher checked={showMap} onChange={(_, value) => setShowMap(value)} />
            Show map
          </MapSwitch>

          <PerPage>
            <div>Show</div>
            <Select
              options={PER_PAGE_OPTIONS}
              value={clientFilters.perPage}
              onChange={(_, value) => updateFilters({ perPage: value })}
              isClearable={false}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
            <div>requests per {isGroupedData ? 'group' : 'page'}</div>
          </PerPage>
        </RightSide>
      </SelectionAndPerPage>
      <Table>
        <thead>
          <tr>
            <TitleCell key={COLUMNS[0].id} style={columnStyle[COLUMNS[0].id]}>
              <Checkbox isChecked={isAllSelected} onChange={toggleAllSelected} />
              {COLUMNS[0].name}
            </TitleCell>

            {COLUMNS.slice(1).map(({ id, name }) => (
              <th key={id} style={columnStyle[id]} title={name}>
                <MultilineEllipsis lines={2}>{name}</MultilineEllipsis>
              </th>
            ))}

            {showMap && <th key="mapSpacer" style={{ width: mapWidth, minWidth: mapWidth, maxWidth: mapWidth }} />}
          </tr>
        </thead>
      </Table>
    </>
  );
};
