import { colors, scroll } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 24px 24px;
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
`;

export const Breadcrumbs = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 4px;

  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    color: ${colors.green};
    text-decoration: none;
  }
`;

export const Header = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 16px;
  width: calc((100% - 260px - 24px) / 2);
  margin-left: 16px;

  > *:last-child {
    margin-left: auto;
  }
`;

export const LastUpdated = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
`;

export const Main = styled.div`
  display: flex;
  gap: 16px;
  flex: 1;
  height: auto;
  overflow: hidden;
`;

export const Sidebar = styled.div`
  width: 260px;
  flex: 0 0 260px;
  min-width: 260px;
  padding: 16px;
  border-radius: 8px;

  border: 1px solid #dfdfe8;

  display: flex;
  flex-direction: column;
`;

export const WorkArea = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  border: 1px solid #dfdfe8;
  border-radius: 8px;
  background-color: #fff;
  display: flex;

  > * {
    flex: 1 1 50%;
    min-width: 0;
  }
`;

export const ProjectPropertiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: auto;
  margin-top: 16px;
`;

export const ProjectPropertiesScrollable = styled.div`
  overflow: auto;
  margin-top: 8px;
  ${scroll};
`;

export const FormLayout = styled.div`
  padding: 16px;
  overflow: auto;
  ${scroll};
`;

export const ConfigAndPreview = styled.div`
  border-left: 1px solid #dfdfe8;
  display: flex;
  flex-direction: column;
`;

export const ConfigAndPreviewHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid #dfdfe8;
`;

export const SidebarItem = styled.div<{ isDragging?: boolean; isDashed?: boolean }>`
  display: flex;
  user-select: none;
  padding: 8px;
  margin-bottom: 8px;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  background: #fff;
  border-style: ${({ isDashed }) => (isDashed ? 'dashed' : 'solid')};
  border-width: 1px;
  border-color: ${({ isDashed }) => (isDashed ? '#C8C8D3' : '#DFDFE8')};
  transform: ${(props) => !props.isDragging && 'translate(0px, 0px) !important'};

  svg {
    color: #9c9caa;
    flex-shrink: 0;
  }
`;

export const SidebarItemClone = styled(SidebarItem)``;

export const RemoveElementButton = styled.div`
  cursor: pointer;
  color: #9c9caa;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  transition: color 0.15s ease-in-out;

  &:hover {
    svg {
      color: #000;
    }
  }
`;

export const WorkAreaItem = styled.div<{ isSelected?: boolean }>`
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid;
  border-color: ${({ isSelected }) => (isSelected ? '#009688' : '#DFDFE8')};
  border-radius: 8px;
  user-select: none;
  background-color: ${({ isSelected }) => (isSelected ? '#E3F6F4' : '#fff')};
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  transition:
    border-color 0.15s ease-in-out,
    background-color 0.15s ease-in-out;

  gap: 8px;

  svg {
    color: #9c9caa;
    flex-shrink: 0;
  }

  ${RemoveElementButton} {
    visibility: hidden;
  }

  &:hover {
    ${RemoveElementButton} {
      visibility: visible;
    }
  }
`;

export const WorkAreaGroupItemHeader = styled.div`
  font-size: 18px;
  font-weight: 500;
  padding: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  transition: background-color 0.15s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${RemoveElementButton} {
    visibility: hidden;
  }

  &:hover {
    ${RemoveElementButton} {
      visibility: visible;
    }
  }
`;

export const WorkAreaGroupItem = styled.div<{ isSelected?: boolean }>`
  border-radius: 8px;
  border: 1px dashed;
  border-color: ${({ isSelected }) => (isSelected ? '#009688' : '#C8C8D3')};
  margin-bottom: 20px;
  background-color: #fff;
  transition: border-color 0.15s ease-in-out;

  ${WorkAreaGroupItemHeader} {
    background-color: ${({ isSelected }) => (isSelected ? '#E3F6F4' : '#F7F7FC')};
  }
`;

export const WorkAreaGroupItemBody = styled.div`
  padding: 16px;
`;

export const SectionTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SectionTitleDescription = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
`;

export const FieldTypesSectionTitle = styled(SectionTitle)`
  margin-bottom: 16px;
`;

export const FormFieldsSectionTitle = styled(SectionTitle)`
  margin-bottom: 24px;
`;

export const ProjectPropertiesSectionTitle = styled(SectionTitle)`
  margin-bottom: 8px;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }
`;

export const ProjectPropertiesGroupTitle = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
`;

export const PlaceholderFieldName = styled.span`
  color: #828d9a;
`;

export const GroupItemsPlaceholder = styled.span`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
`;
