import { IntegrationDTO, IntegrationProgress } from '@types';
import { Integration } from '@generated/types/graphql';
import { Urls } from '@services/api/base/axios';
import { deleteRequest, get, post } from '@services/api/base';

export default {
  createIntegration: (userId: number, companyId: number, dto: IntegrationDTO) =>
    post<Integration>(`${Urls.fleet}/integrations?userId=${userId}&companyId=${companyId}`, dto),

  deleteIntegration: (id: number) => deleteRequest<Integration>(`${Urls.fleet}/integrations/${id}`),

  getInitiateProgress: (id: number) => get<IntegrationProgress>(`${Urls.fleet}/integrations/${id}/progress`),

  monitorIntegration: (id: number, dto: { monitor: boolean }) =>
    post<void>(`${Urls.fleet}/integrations/${id}/monitor`, dto)
};
